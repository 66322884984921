import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Parallax, Autoplay } from 'swiper'

import 'swiper/css/bundle'

import TimeLogo from "../../../src/assets/images/home/awardLogos/time.svg"
import BlueGreenLogo from "../../../src/assets/images/home/awardLogos/blueGreen.svg"
import GuardianLogo from "../../../src/assets/images/home/awardLogos/the_guardian.svg"
import FastLogo from "../../../src/assets/images/home/awardLogos/fast_company.svg"
import VenturesLogo from "../../../src/assets/images/home/awardLogos/ventures.svg"
import DerivativeLogo from "../../../src/assets/images/home/awardLogos/derivative.svg"
import NRCLogo from "../../../src/assets/images/home/awardLogos/nrc.svg"

const AwardCard = ({ title, logo }) => {
    return <div className='flex flex-col items-center'>
        <div className='flex space-x-6 h-16'>
            <p>
                <svg width="19" height="15" viewBox="0 0 19 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.37396 12.9241C2.16803 14.0585 3.30241 14.569 4.66366 14.569C5.79804 14.569 6.70554 14.2854 7.38617 13.6615C8.0668 13.0376 8.40711 12.2435 8.40711 11.2793C8.40711 10.3151 8.12352 9.57771 7.55633 9.01052C6.98914 8.50005 6.30851 8.21646 5.40101 8.21646C4.55022 8.21646 3.92631 8.44333 3.47256 8.89708C3.41584 8.78364 3.41584 8.50005 3.41584 8.15973C3.41584 7.02536 3.75616 5.9477 4.55022 5.0402C5.28757 4.13269 6.47867 3.67894 8.18024 3.62222V0.956432C5.6846 1.06987 3.69944 1.7505 2.28147 3.11175C0.863492 4.473 0.182865 6.40145 0.182865 8.78364C0.182865 10.4285 0.579898 11.7897 1.37396 12.9241ZM11.1863 12.9241C11.9804 14.0585 13.1148 14.569 14.476 14.569C15.6104 14.569 16.5179 14.2854 17.1985 13.6615C17.8792 13.0376 18.2195 12.2435 18.2195 11.2793C18.2195 10.3151 17.9359 9.57771 17.3687 9.01052C16.8015 8.50005 16.1209 8.21646 15.2134 8.21646C14.3626 8.21646 13.7387 8.44333 13.2849 8.89708C13.2282 8.78364 13.2282 8.50005 13.2282 8.15973C13.2282 7.02536 13.5685 5.9477 14.3626 5.0402C15.0999 4.13269 16.291 3.67894 17.9926 3.62222V0.956432C15.497 1.06987 13.5118 1.7505 12.0938 3.11175C10.6759 4.473 9.99524 6.40145 9.99524 8.78364C9.99524 10.4285 10.3923 11.7897 11.1863 12.9241Z" fill="#313131" />
                </svg>
            </p>
            <p className='text-lg lg:text-xl text-center'>
                {title}
            </p>
            <p>
                <svg width="19" height="15" viewBox="0 0 19 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.626 12.9241C16.832 14.0585 15.6976 14.569 14.3363 14.569C13.202 14.569 12.2945 14.2854 11.6138 13.6615C10.9332 13.0376 10.5929 12.2435 10.5929 11.2793C10.5929 10.3151 10.8765 9.57771 11.4437 9.01052C12.0109 8.50005 12.6915 8.21646 13.599 8.21646C14.4498 8.21646 15.0737 8.44333 15.5274 8.89708C15.5842 8.78364 15.5842 8.50005 15.5842 8.15973C15.5842 7.02536 15.2438 5.9477 14.4498 5.0402C13.7124 4.13269 12.5213 3.67894 10.8198 3.62222V0.956432C13.3154 1.06987 15.3006 1.7505 16.7185 3.11175C18.1365 4.473 18.8171 6.40145 18.8171 8.78364C18.8171 10.4285 18.4201 11.7897 17.626 12.9241ZM7.81366 12.9241C7.0196 14.0585 5.88522 14.569 4.52396 14.569C3.38958 14.569 2.48208 14.2854 1.80145 13.6615C1.12083 13.0376 0.780513 12.2435 0.780513 11.2793C0.780513 10.3151 1.06411 9.57771 1.6313 9.01052C2.19849 8.50005 2.87911 8.21646 3.78662 8.21646C4.6374 8.21646 5.26131 8.44333 5.71506 8.89708C5.77178 8.78364 5.77178 8.50005 5.77178 8.15973C5.77178 7.02536 5.43147 5.9477 4.6374 5.0402C3.90005 4.13269 2.70896 3.67894 1.00739 3.62222V0.956432C3.50302 1.06987 5.48818 1.7505 6.90616 3.11175C8.32413 4.473 9.00476 6.40145 9.00476 8.78364C9.00476 10.4285 8.60773 11.7897 7.81366 12.9241Z" fill="#313131" />
                </svg>
            </p>
        </div>
        <div className='flex justify-center items-center mt-14 lg:mt-10'>
            <img
                className="w-28 lg:w-28"
                alt=""
                src={logo}
            />
        </div>
    </div>
}

const AwardBy = () => {
    const data = [
        {
            id: 1,
            logo: TimeLogo,
            title: <>The Best Inventions <br /> Of 2021</>,
        },
        {
            id: 2,
            logo: BlueGreenLogo,
            title: <>Transformative Potential <br />For Improving Energy Access</>,
        },
        {
            id: 3,
            logo: GuardianLogo,
            title: <>A Game-Changing Approach <br /> To Overcoming Electricity Instability</>,
        },
        // {
        //     id: 4,
        //     logo: FastLogo,
        //     title: <>Developing Critical Tools In <br />  Making Energy Accessible</>,
        // },
        {
            id: 4,
            logo: NRCLogo,
            title: <>Smaller, Smarter, Affordable <br />  Energy Solutions</>,
        },
        {
            id: 5,
            logo: VenturesLogo,
            title: <>One Of 7 notable Inventions <br />  In Africa In 2021</>,
        },
        {
            id: 6,
            logo: DerivativeLogo,
            title: <>Simple, Yet Powerful <br />  Energy Solutions </>,
        },
    ]
    return (
        <div className='py-8 md:py-12 relative bg-[#fcfcfc]'>
            <div className='flex flex-row award-slide'>
                <Swiper
                    spaceBetween={30}
                    modules={[Parallax, Autoplay]}
                    className="w-full"
                    slidesPerView={3}
                    parallax={true}
                    speed={1000}
                    autoplay={{
                        delay: 1000,
                        disableOnInteraction: false,
                    }}
                    loop={true}
                    breakpoints={{
                        320: {
                            slidesPerView: 1,
                            spaceBetween: 20
                        },
                        480: {
                            slidesPerView: 2,
                            spaceBetween: 30
                        },
                        640: {
                            slidesPerView: 3,
                            spaceBetween: 30
                        }
                    }}
                >
                    {
                        data?.map(datum => <SwiperSlide key={datum?.id}>
                            <AwardCard
                                key={datum?.id}
                                title={datum?.title}
                                logo={datum?.logo}
                            />
                        </SwiperSlide>
                        )
                    }

                </Swiper>
            </div>
        </div>
    )
}

export default AwardBy