import React from 'react';
import { Contact, Newsletter } from '../components';
import AwardBy from '../components/HomeComponents/AwardBy';
import TimeMagComponent from "../components/HomeComponents/TimeMagComponent";
import { Jumbotron, ResearchPapers } from '../components/ResearchComponents';
import Layout from '../containers/Layout'

export default function ResearchPage() {
  return(
      <Layout title={"Research"}>
          <Jumbotron/>
          <AwardBy />
          <ResearchPapers/>
          <Contact/>
          <Newsletter/>
      </Layout>
  );
}
