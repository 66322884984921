import React from 'react';
import * as Animate from "react-reveal"

export default function Cards({ paper }) {
    const { title, content, image, desc, link } = paper

    return (
        <div className='flex flex-col w-full md:flex-row md:items-center bg-white research-paper-card py-6 px-8'>
            <Animate.Fade bottom>
                <div className='w-full md:w-4/12 lg:w-5/12 mb-4 lg:mb-0'>
                    <Animate.Fade left>
                        <img src={image} className=" rounded-lg h-full w-full" alt={desc} />
                    </Animate.Fade>
                </div>

                <div className='md:pl-6 lg:pl-8 pt-4 lg:pt-4 flex flex-col  justify-start items-start w-full md:w-7/12 lg:w-6/12'>
                    <Animate.Fade bottom>
                        <h4 className='font-semibold text-lg mb-4 lg:mb-8 lg:text-xl lg:leading-9 capitaise w-11/12 lg:w-10/12'>{title}</h4>
                        <p className='text-base lg:text-lg lg:leading-8 mb-4 lg:mb-8 text-cstm-grey'>{content}</p>
                        <a href={link} target="_blank" className='text-sm lg:text-sm-15 font-medium py-3 px-4 lg:px-8 text-cstm-green rounded border border-cstm-green ease-in duration-300 hover:bg-cstm-green hover:text-[#fff]'>Download for free</a>
                    </Animate.Fade>
                </div>
            </Animate.Fade>
        </div>
    );

}
