import React from 'react';
import Cards from './Cards';
import { papers } from './papers';
import * as Animate from "react-reveal"

export default function researchPapers() {
  return (
    <section className="bg-[#fff]">
      <div className="w-11/12 mx-auto py-16 lg:py-20 flex flex-col max-w-7xl">
        <div className="flex flex-col justify-center md:justify-center mb-8  items-center md:items-start mx-auto">
          <Animate.Fade bottom>
            <h1 className="text-3xl mb-4 font-semibold md:text-3xl lg:text-4xl xl:text-5xl text-center">
              Some of our Recent Research
            </h1>
          </Animate.Fade>
        </div>

        <div className="mt-6 lg:mt-10 w-full flex flex-col gap-y-24">
          {papers.map((paper) => {
            return <Cards key={paper.id} paper={paper} />;
          })}
        </div>
      </div>
    </section>
  );
}
