import React from 'react';
import Tag from '../Tag'
import * as Animate from "react-reveal"
import ResearchBgImg from "../../assets/images/research.webp"

export default function Jumbotron() {
    return (
        <>
            <main className='h-screen bg-white pt-4 md:pt-12 lg:pt-20 pb-8 lg:pb-12 h-[80%] bg-cover bg-center'>
                <div className='w-11/12 m-auto py-16 max-w-7xl'>
                    <div className='w-full flex flex-col md:flex-row justify-between items-center'>
                        <div className='w-full flex flex-col mb-4 justify-start items-start  md:w-[50%] lg:w-[54%]'>
                            <Animate.Fade bottom>
                                <Tag text='Research' />
                                <h1 className='mt-6 lg:mt-8 text-3xl font-semibold leading-tight md:text-3xl lg:text-4xl xl:text-5xl lg:leading-tight xl:leading-tight capitalize'>
                                    Research-based Innovations with first principle thinking for radical scale and impact.
                                </h1>

                            </Animate.Fade>
                        </div>

                        <div className='w-full md:w-[45%] lg:w-[42%] md:mt-16'>
                            <Animate.Fade right>
                                <p className='text-md  md:text-lg mt-6 lg:mt-8 lg:text-xl lg:leading-10 text-cstm-grey lg:pr-6 xl:pr-12'>
                                    Our Innovation is driven by quality research to understand critical environmental challenges.
                                    Insights obtained are leveraged to build products that positively impact our environment and create a better future.
                                </p>

                            </Animate.Fade>
                        </div>
                    </div>
                </div>
            </main>
            <div className='relative'>
                <img className='w-fit' src={ResearchBgImg} alt='research' />
            </div>
        </>
    );
}
